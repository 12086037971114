<template>
    <section v-if="mobile">
        <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
            <MobileHeader />
             <h1 class="font-heading text-h4 text-center text-sepiaBlack dark:text-white"> Az idővonalam </h1>
            <input type='text' id='quicksearchInput' placeholder="Search" v-model="quicksearchInput" ref="input" v-on:change="searchChange">

            <!-- <input type='text' id='quicksearchInput' placeholder="Keresés" v-model="quicksearchInput" ref="input" v-on:change="searchChange"> -->
            <ul>
                <li v-for="(post) of posts_mobile" :key="post.id">
                    <EntityTimeLineMobile :content="post" :linkAllowe="linkAllowe(post.id)"/>
                </li> 
            </ul>
        </div>
    </section>
    <section v-else>
        <div class="max-w-screen-tv-main-axis max-h-screen-tv-cross-axis mx-auto py-2">
            <Header :backArrow="true" :back="false">
            </Header>
            <h1 class="font-heading text-h1 text-halfColonialWhite"> Az idővonalam 
            </h1>
            <div class="flex" v-if="landscape">
                <ul class="flex flex-nowrap mx-10 pb-10 pt-24 overflow-x-scroll hide-scroll-bar relative">
                    <!-- <span class="border-t border-redDamask absolute w-full top-24"></span> -->
                    <li v-for="(post, index) in posts" :key="post.id" class="inline-block relative min-w-80 w-80 border-t border-redDamask">
                        <div :class="'pt-' + marginGrid(index)">
                            <span class="absolute block left-20 -top-24 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24">{{ newIndex(index) }}</span>
                            <span :class="'h-' + lineHeight(index) + ' ' + 'border-l border-redDamask block absolute left-20 top-0 transform -translate-x-1/2' "></span>
                            <EntityTimeLine class="block" tv :content="post" :itemIndex="index" :linkAllowe="linkAllowe(post.id)"/>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else>
                <ul class="mx-10 pb-10 pl-24 relative">
                    <!-- <span class="border-t border-redDamask absolute w-full top-24"></span> -->
                    <li v-for="(post, index) in posts" :key="post.id" class="block relative border-l border-redDamask pb-16">
                        <div :class="'pl-' + marginGridPortre(index)">
                            <span class="absolute block -left-16 top-4 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24	">{{ newIndex(index) }}</span>
                            <span :class="'w-' + lineHeightPortre(index) + ' ' + 'border-t border-redDamask block absolute left-0 top-20' "></span>
                            <EntityTimeLinePortre class="block" tv :content="post" :itemIndex="index" :linkAllowe="linkAllowe(post.id)"/>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>

import Header from "@/components/Header.vue";
import MobileHeader from "@/components/mobile/Header.vue";
import EntityTimeLine from "@/components/tablet/EntityTimeLine.vue";
import EntityTimeLineMobile from "@/components/mobile/EntityTimeLine.vue";
import EntityTimeLinePortre from "@/components/tablet/EntityTimeLinePortre.vue";
import store from '../store'

export default {
    name: "Tablet",
    components: {
        Header,
        MobileHeader,
        EntityTimeLineMobile,
        EntityTimeLinePortre,
        EntityTimeLine,
    },
    mounted() {
        this.getPosts();
        this.myEventHandler();
    },
    data(){
        return {
            posts: [],
            posts_mobile: [],
            baseURL: process.env.VUE_APP_AXIOS_URL,
            id: this.$route.params.id,
            mobile: false,
            landscape: true,
            linkEnabeled: [],
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        linkAllowe(entity_id) {
            const timelineCheckResult = store.dispatch('timelineCheck', entity_id);
            timelineCheckResult.then(timeline_res => {
                if (timeline_res.in) {
                    this.linkEnabeled[entity_id] = true;
                } else {
                    this.linkEnabeled[entity_id] = false;
                }
            }).catch(() => {
                this.linkEnabeled[entity_id] = false;
            });

            return this.linkEnabeled[entity_id];
        },
        myEventHandler() {
            if (window.innerWidth <= 768) {
                this.mobile = true;
            } else {
                this.mobile = false;
                if (screen.orientation.type == 'landscape-primary') {
                    this.landscape = true;
                } else {
                    this.landscape = false;
                }
            }
        },
        getPosts() {
            this.axios.get(this.baseURL + `/wp-json/wp/v2/timeline/` + this.id, {}, {})
            .then(response => {
                this.posts = response.data;
                this.posts_mobile = response.data;
                if (response.data.length < 1) {
                    alert("Empty timeline");
                    if (localStorage.getItem('user_pin')) {
                        this.$router.push('/mobile/menu');
                    } else {
                        this.$router.push('/pin-check');
                    }
                }
            }).catch( (error) => {
                console.log(error);
            });
        },
        searchChange() {
            const search_input = document.querySelector('#quicksearchInput').value;
            if (search_input.length > 2 ) {
                this.posts_mobile = [];
                for (let i = 0; i < this.posts.length; i++) {
                    if (this.posts[i].title.toLowerCase().includes(search_input.toLowerCase())) {
                        this.posts_mobile.push(this.posts[i]);
                    }
                }
            } else {
                this.posts_mobile = this.posts
            }
        },
        gridRowCount(value) {
            return parseInt((value % 4)+1);
        },
        marginGrid(value) {
            return parseInt((value % 3) * 16);
        },
        lineHeight(value) {
            return parseInt(20 + ((value % 3) * 16));
        },
        newIndex: function(value) {
            if(value >= 10) {
                return value + 1;
            } 
            return `0${value+1}`
        },
        marginGridPortre(value) {
            return parseInt(((value % 2) + 1) * 20);
        },
        lineHeightPortre(value) {
            return parseInt(20 + ((value % 2) * 16));
        },
    },
    watch: {
        quicksearchInput: function(value) {
            console.log(value);
            this.onChange();
        },
    },
};
</script>
